import { Link } from '@features/shared/components/link';
import styles from './NavigationList.module.scss';
import classNames from 'classnames';
import theme from 'config/theme';
import { LoginLink } from 'src/features/shared/components/login-link';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { IMenuItem } from '../../contentful/types/IMenuItem';

interface INavigationListProps {
  items?: IMenuItem[];
  isHorizontal?: boolean;
}

export function NavigationList({
  items = [],
  isHorizontal,
}: INavigationListProps) {
  const pushEvent = (item: IMenuItem) => {
    pushGA4Event(GA4_EVENTS.menu, {
      interaction_type: 'click',
      link_test: item.link?.label,
      link_url: item.link?.href || `/${item.link?.slug}`,
    });
  };

  return (
    <ul
      className={classNames(styles.list, { [styles.horizontal]: isHorizontal })}
    >
      {theme.login && (
        <li className={classNames([styles.item, styles.login])}>
          <LoginLink />
        </li>
      )}
      {items &&
        items.map((item) => (
          <li key={item.link?.label} className={styles.item}>
            <Link
              onClick={() => pushEvent(item)}
              href={item.link?.href || `/${item.link?.slug}`}
            >
              {item.link?.label}
            </Link>
          </li>
        ))}
    </ul>
  );
}
