import { useState } from 'react';

export type IUseSheetReturn = {
  isOpen: boolean;
  isOpening: boolean;
  isClosing: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export function useSheet(
  isDefaultOpen = false,
  transitionTimout = 300,
): IUseSheetReturn {
  const [isOpen, setOpen] = useState(isDefaultOpen);
  const [isClosing, setClosing] = useState(false);
  const [isOpening, setOpening] = useState(false);

  const close = () => {
    setOpening(false);
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
    }, transitionTimout);
  };

  const open = () => {
    setClosing(false);
    setOpening(true);
    setTimeout(() => {
      setOpen(true);
      setOpening(false);
    }, transitionTimout);
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  return {
    isOpen,
    isOpening,
    isClosing,
    open,
    close,
    toggle,
  };
}
