import classNames from 'classnames'
import { MouseEventHandler, useEffect } from 'react'
import { IUseSheetReturn } from '../../hooks/use-sheet'
import styles from './Overlay.module.scss'

export interface IOverlayProps {
  sheet: IUseSheetReturn
  className?: string
  accessibleLabel?: string
  variant?: 'opaque' | 'full' | 'none'
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const Overlay = ({
  sheet,
  className,
  accessibleLabel,
  variant = 'opaque',
  onClick
}: IOverlayProps) => {
  const { isClosing, isOpening, isOpen, close } = sheet

  /*
    Prevent background scrolling on mobile
   */
  useEffect(() => {
    const amountOfOverlays = document.querySelectorAll(
      '[data-testid="overlay"]'
    ).length

    if (!isClosing && !isOpening && isOpen && variant !== 'none') {
      document.body.style.overflow = 'hidden'
    }

    if (amountOfOverlays < 2) {
      if (isClosing || isOpening) {
        document.body.style.overflow = 'unset'
      }

      // if the second overlay is closed, it will not set overflow to "unset"
      return () => {
        document.body.style.overflow = 'unset'
      }
    }
  }, [isClosing, isOpen, isOpening])

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e)
    close()
  }

  return (isOpen || isOpening) && variant !== 'none' ? (
    <button
      type="button"
      className={classNames(
        styles.overlay,
        isClosing && styles.closing,
        isOpening && styles.opening,
        isOpen && styles.isOpen,
        { [styles.full]: variant === 'full' },
        className
      )}
      onClick={handleOnClick}
      aria-label={accessibleLabel}
      data-testid="overlay"
    />
  ) : null
}
