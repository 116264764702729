import { useIntl } from 'domains/i18n';
import { ComponentProps, useMemo } from 'react';
import theme from 'src/config/theme';
import { ACTIVE_FUNNELS } from 'src/features/pg-funnel/config/active-funnels';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { Button } from '../button';

interface IJoinButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  clubId?: number;
}

export function JoinButton({ clubId, ...props }: IJoinButtonProps) {
  const { formatMessage } = useIntl();
  const label = useMemo(() => formatMessage('join'), [formatMessage]);

  const checkoutUrl = theme.name === 'sportcity' ? 'checkout' : 'funnel';

  const onClick = () => {
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name: 'click_cta_header',
    });
  };

  return (
    <Button
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      href={`/${checkoutUrl}/${ACTIVE_FUNNELS[theme.name]?.DEFAULT}${
        clubId ? `?clubId=${clubId}` : ''
      }`}
      onClick={onClick}
      isHtmlTag
      size="sm"
      {...props}
    >
      {label}
    </Button>
  );
}
