export const ACTIVE_FUNNELS = {
  sportcity: {
    CORPORATE: 'corporate',
    DEFAULT: 'default',
    BACK_TO_SPORT: 'back-to-sport',
    DAY_PASS: 'dagpas',
    STUDENT: 'student',
    JUST: 'just',
    AHOLD: 'ahold',
    MIND_US: 'mind-us',
    NN: 'nn',
    CZ: 'cz',
    OHRA: 'ohra',
    KLM: 'klm',
  },
  sportcitypremium: {
    DEFAULT: 'cornelis-schuyt',
  },
};
